import dayjs from 'dayjs'

import { ICourse } from '../courses'
import { ICourseEnrollment } from '.'

export const checkCourseStatus = (course: ICourse, enrollment: ICourseEnrollment | null) => {
  const time = new Date()
  // 지원 완료 (수강 대기 중 ready, 수강 중 ing까지 포함)
  const ingAndReady = enrollment?.status
  // 지원 완료
  const isApplyCompleted =
    typeof ingAndReady !== 'undefined' && (ingAndReady === 'ready' || ingAndReady === 'ing')
  // 클래스에 대한 수강권한이 있는지 여부 (온디멘드, 선정 완료되고 시작일이 된 기수제)
  const isEnabled = typeof ingAndReady !== 'undefined' && ingAndReady === 'ing'
  // 기수제 강의인지 (지원서 여부 상관 없이)
  const isCardinalNumberCourse = course.applyStartedAt !== null && course.applyEndedAt !== null
  // 지원서가 필요한가
  const isNeededApplication = course.applyType !== null
  // 모집 기간 전
  const isBeforeApplyStarted = dayjs(time).isBefore(course.applyStartedAt)
  // 모집 기간
  const isApplyStarted =
    dayjs(time).isSameOrAfter(course.applyStartedAt) && dayjs(time).isBefore(course.applyEndedAt)
  // 모집 종료
  const isApplyEnded = dayjs(time).isSameOrAfter(course.applyEndedAt)
  // 클래스 오픈
  const isCourseStarted = dayjs(time).isSameOrAfter(course.startedAt)

  // 온디멘드
  if (!isCardinalNumberCourse) {
    // 구매 안함
    if (!isEnabled) return 'enrollment'
    // 구매함
    else return 'play'

    // 기수제 클래스
  } else {
    // 사전 알림 기간
    if (isBeforeApplyStarted) return 'early'
    // 모집 기간
    if (isApplyStarted) {
      // 클래스 오픈 대기
      if (isApplyCompleted) return 'wait'
      else {
        // 지원서가 필요함
        if (isNeededApplication) return 'apply'
        // 지원서가 필요하지 않음
        else return 'enrollment'
      }
    }
    // 모집 종료
    if (isApplyEnded) {
      // 지원안함
      if (!isApplyCompleted) return 'closed'
      // 지원함
      else {
        // 클래스 오픈
        if (isCourseStarted && isEnabled) return 'play'
        // 클래스 오픈 대기
        else return 'wait'
      }
    }
  }
}
