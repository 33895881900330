import dayjs from 'dayjs'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'
import _ from 'lodash'

import { ICourse } from '../courses'
import { ICourseEnrollment } from '../permission'

dayjs.extend(isSameOfAfter)

export const checkCanApplyCourse = (
  course: ICourse,
  enrollment: ICourseEnrollment | null,
): boolean => {
  const curDate = new Date()
  const enrollmentStatus = enrollment?.status

  if (
    _.isEqual(course?.courseType, 'ondemand') ||
    dayjs(curDate).isBefore(course?.applyStartedAt) ||
    dayjs(curDate).isSameOrAfter(course?.applyEndedAt) ||
    _.isEqual(enrollmentStatus, 'ready') ||
    _.isEqual(enrollmentStatus, 'ing')
  ) {
    return false
  }
  return true
}
