import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import {
  ICourse,
  ICourseSections,
  IFAQPost,
  IKDTKlass,
  IKDTKlassProgressStat,
  IProgress,
  IProgressResource,
  IResourceProgressComplete,
} from './types'

export const CLEAR_COURSE_STATE = 'courses/CLEAR_COURSE_STATE'

export const clearCourseState = createAction('courses/CLEAR_COURSE_STATE')<void>()
export const clearKDTProgressStatList = createAction('courses/CLEAR_KDT_PROGRESS_STAT_LIST')<void>()

export const fetchKDTKlassProgressStatList = createAsyncAction(
  'courses/FETCH_KDT_KlASS_PROGRESS_STAT_LIST',
  'courses/FETCH_KDT_KlASS_PROGRESS_STAT_LIST_SUCCESS',
  'courses/FETCH_KDT_KlASS_PROGRESS_STAT_LIST_FAIL',
)<unknown, IKDTKlassProgressStat[], AxiosError>()

export const fetchKDTKlassAttr = createAsyncAction(
  'courses/FETCH_KDT_KlASS_ATTR',
  'courses/FETCH_KDT_KlASS_ATTR_SUCCESS',
  'courses/FETCH_KDT_KlASS_ATTR_FAIL',
)<unknown, IKDTKlass, AxiosError>()

export const fetchKDTBasicKlassList = createAsyncAction(
  'courses/FETCH_KDT_BASIC_KlASS_LIST',
  'courses/FETCH_KDT_BASIC_KlASS_LIST_SUCCESS',
  'courses/FETCH_KDT_BASIC_KlASS_LIST_FAIL',
)<unknown, IKDTKlass[], AxiosError>()

export const fetchCourseDetail = createAsyncAction(
  'courses/FETCH_COURSE_DETAIL',
  'courses/FETCH_COURSE_DETAIL_SUCCESS',
  'courses/FETCH_COURSE_DETAIL_FAIL',
)<unknown, ICourse, AxiosError>()

export const fetchCourseSections = createAsyncAction(
  'courses/FETCH_COURSE_SECTIONS',
  'courses/FETCH_COURSE_SECTIONS_SUCCESS',
  'courses/FETCH_COURSE_SECTIONS_FAIL',
)<unknown, ICourseSections, AxiosError>()

export const fetchProgressList = createAsyncAction(
  'courses/FETCH_PROGRESS_LIST',
  'courses/FETCH_PROGRESS_LIST_SUCCESS',
  'courses/FETCH_PROGRESS_LIST_FAIL',
)<unknown, IProgress[], AxiosError>()

export const fetchProgressNow = createAsyncAction(
  'courses/FETCH_PROGRESS_NOW',
  'courses/FETCH_PROGRESS_NOW_SUCCESS',
  'courses/FETCH_PROGRESS_NOW_FAIL',
)<unknown, IProgressResource, AxiosError>()

export const fetchRecommendCourseList = createAsyncAction(
  'courses/FETCH_RECOMMEND_COURSE_LIST',
  'courses/FETCH_RECOMMEND_COURSE_LIST_SUCCESS',
  'courses/FETCH_RECOMMEND_COURSE_LIST_FAIL',
)<unknown, IListDataConvertResp<ICourse>, AxiosError>()

export const fetchCourseFAQList = createAsyncAction(
  'courses/FETCH_FAQ_LIST',
  'courses/FETCH_FAQ_LIST_SUCCESS',
  'courses/FETCH_FAQ_LIST_FAIL',
)<unknown, IFAQPost[], AxiosError>()
