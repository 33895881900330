import axios from 'axios'

import { convertCaseList, ConvertType } from '../../utils/convertString'
import { ICourseEnrollment, ICourseEnrollmentResponse } from '.'
import { ICoursePermission, ICoursePermissionResponse, IEnrollmentHistory } from './types'

export const requestEnrollment = async (courseId: string) => {
  const resp = await axios.get<ICourseEnrollmentResponse>(
    `${window.apiEndpoint}/my/permission/v2/enrollments/courses/${courseId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourseEnrollment
}

export const requestCoursePermission = async (courseId: string) => {
  const resp = await axios.get<ICoursePermissionResponse>(
    `${window.apiEndpoint}/my/permission/v1/enrollments/courses/${courseId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as ICoursePermission
}

export const requestCourseSinglePermission = async (courseId: string) => {
  const resp = await axios.get<IListDataResp<IEnrollmentHistory>>(
    `${window.apiEndpoint}/my/permission/v1/enrollments?course_id=${courseId}&page=1&per_page=1`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}
