import { Dispatch } from 'redux'
import { AsyncActionCreatorBuilder } from 'typesafe-actions'

type IAnyAsyncActionCreator = AsyncActionCreatorBuilder<any, any, any>

export default function createAsyncThunk<
  A extends IAnyAsyncActionCreator,
  F extends (...params: any[]) => Promise<any>
>(asyncActionCreator: A, promiseCreator: F) {
  type Params = Parameters<F>
  return function thunk(...params: Params) {
    return async (dispatch: Dispatch) => {
      const { request, success, failure } = asyncActionCreator
      dispatch(request(undefined))
      try {
        const result = await promiseCreator(...params)
        dispatch(success(result))
      } catch (e) {
        dispatch(failure(e))
      }
    }
  }
}
