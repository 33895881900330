import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react'
import { BaseReactPlayerProps } from 'react-player/base'

export interface IPlayerContextState {
  player: BaseReactPlayerProps | null
  setPlayer: Dispatch<SetStateAction<BaseReactPlayerProps>>
}

const PlayerContext = createContext<IPlayerContextState>({
  player: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPlayer: () => {},
})

export interface IAuthProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

const PlayerProvider: FC<IAuthProviderProps> = ({ children }) => {
  const [playerVideo, setPlayerVideo] = useState<BaseReactPlayerProps>({
    playing: true,
    muted: true,
    volume: 0.5,
  })

  return (
    <PlayerContext.Provider value={{ player: playerVideo, setPlayer: setPlayerVideo }}>
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerProvider
export const useKlassReactPlayerPlayer = () => useContext(PlayerContext)
