import { AnyAction, combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { clearPurchaseOrder, fetchPurchaseOrderInfo, IPurchaseOrderInfo } from '.'
import {
  applyCoupon,
  clearOrder,
  fetchCarts,
  fetchCreditCard,
  fetchPaymentsHistory,
  requestPaymentsAction,
  updateCreditCard,
} from './actions'
import { ICreditCardResponse, IOrderAction, IOrderInfo, IPayment } from './types'

export const isLoading = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction(
    [
      updateCreditCard.request,
      fetchCreditCard.request,
      fetchCarts.request,
      requestPaymentsAction.request,
      applyCoupon.request,
      fetchPaymentsHistory.request,
      fetchPurchaseOrderInfo.request,
    ],
    () => true,
  )
  .handleAction(
    [
      updateCreditCard.success,
      updateCreditCard.failure,
      fetchCreditCard.success,
      fetchCreditCard.failure,
      fetchCarts.success,
      fetchCarts.failure,
      requestPaymentsAction.success,
      requestPaymentsAction.failure,
      applyCoupon.success,
      applyCoupon.failure,
      fetchPaymentsHistory.success,
      fetchPaymentsHistory.failure,
      fetchPurchaseOrderInfo.success,
      fetchPurchaseOrderInfo.failure,
    ],
    () => false,
  )

export const carts = createReducer<IOrderInfo | null, IOrderAction>(null)
  .handleAction(fetchCarts.success, (state, action) => action.payload)
  .handleAction(applyCoupon.success, (state, action) =>
    state !== null
      ? {
          ...state,
          payment: action.payload,
        }
      : state,
  )
  .handleAction(clearOrder, () => null)

export const purchaseOrderInfo = createReducer<IPurchaseOrderInfo | null, IOrderAction>(null)
  .handleAction(fetchPurchaseOrderInfo.success, (state, action) => action.payload)
  .handleAction(clearPurchaseOrder, () => null)

export const purchaseOrderError = createReducer<string | null, IOrderAction>(null)
  .handleAction(fetchPurchaseOrderInfo.failure, (state, action) => {
    // 요청은 이뤄지고 서버에서 2xx이 아닌 상태 코드 응답
    if (action.payload.response) {
      return '접근할 수 없습니다. 조치를 위해 문의해주세요'
    }
    // 요청은 이뤄졌으나 응답 없음
    else if (action.payload.request)
      return '시스템 오류로 인하여 작업을 완료할 수 없습니다. 재시도해주세요'
    else {
      // 요청 설정 중 문제 발생
      return action.payload.message
    }
  })
  .handleAction([clearPurchaseOrder, fetchPurchaseOrderInfo.request], () => null)

export const payments = createReducer<IPayment | null, IOrderAction>(null)
  .handleAction(requestPaymentsAction.success, (state, action) => action.payload)
  .handleAction(clearOrder, () => null)

export const paymentsHistory = createReducer<IPayment[], IOrderAction>([]).handleAction(
  fetchPaymentsHistory.success,
  (state, action) => action.payload.data,
)

export const workerCourseListPagination = createReducer<IListPagination, IOrderAction>({
  page: 1,
  perPage: 10,
  totalPage: 1,
  count: 0,
  totalCount: 0,
}).handleAction(fetchPaymentsHistory.success, (state, action) => action.payload.pagination)

export const creditCards = createReducer<ICreditCardResponse[], AnyAction>(
  [] as ICreditCardResponse[],
)
  .handleAction(fetchCreditCard.success, (state, action) => action.payload)
  .handleAction(updateCreditCard.success, (state, action) => [action.payload])
  .handleAction(clearOrder, () => [])

const orderReducer = combineReducers({
  isLoading,
  creditCards,
  carts,
  purchaseOrderInfo,
  purchaseOrderError,
  payments,
  paymentsHistory,
})

export default orderReducer
export type IOrderState = ReturnType<typeof orderReducer>
