export const migrations = {
  '5': (state: any) => {
    return {
      ...state,
      courses: {
        ...state.courses,
        sections: null,
        progressNow: {
          id: null,
          title: null,
          type: null,
          isCompleted: false,
          lastTimestamp: null,
          maxTimestamp: null,
        },
      },
      permission: {
        isLoading: false,
        enrollmentList: [],
        coursePermission: {
          isEnabled: false,
          startAt: null,
          expireAt: null,
          progressRate: null,
        },
      },
      showrooms: {
        isLoading: false,
        showroomList: [],
      },
    }
  },
  '6': (state: any) => {
    return {
      auth: {
        isLoading: false,
        profile: null,
      },
      _persist: { version: 3, rehydrated: true },
    }
  },
}
