import _ from 'lodash'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface IGclidQueryParams {
  children: ReactNode
}

interface IGclidContextState {
  gclid: string
  expire: number
}

const GclidContext = createContext<IGclidContextState | undefined>({
  gclid: '',
  expire: 0,
})

export const GclidQueryParamsProvider = ({ children }: IGclidQueryParams) => {
  const router = useRouter()
  const [gclid, setGclid] = useState<IGclidContextState>()

  useEffect(() => {
    const getSaveGclidQuery: string | null = localStorage.getItem('gclid')
    const getGclidQuery: any = _.pickBy(router.query, (value, key) => {
      return _.startsWith(key, 'gclid')
    })

    if (_.isEmpty(getSaveGclidQuery)) {
      if (router.query) {
        if (!_.isEmpty(getGclidQuery)) {
          const gclidObj = {
            ...getGclidQuery,
            expire: Date.now() + 1000 * 60 * 60 * 24 * 50,
          }
          localStorage.setItem('gclid', JSON.stringify(gclidObj))
          setGclid(getGclidQuery)
        }
      }
    } else {
      const gclidParse: IGclidContextState = JSON.parse(getSaveGclidQuery as string)

      if (
        (_.isEmpty(getGclidQuery) || gclidParse.gclid === getGclidQuery.gclid) &&
        Date.now() > gclidParse.expire
      ) {
        localStorage.removeItem('gclid')
      }

      if (!_.isEmpty(getGclidQuery)) {
        if (gclidParse.gclid !== getGclidQuery.gclid) {
          const gclidObj = {
            ...getGclidQuery,
            expire: Date.now() + 1000 * 60 * 60 * 24 * 50,
          }
          localStorage.setItem('gclid', JSON.stringify(gclidObj))
          setGclid(getGclidQuery)
        }
      }
    }
  }, [])

  return <GclidContext.Provider value={gclid}>{children}</GclidContext.Provider>
}

export const useGclidQueryParams = () => useContext(GclidContext)
