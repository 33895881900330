import { NextComponentType, NextPageContext } from 'next'
import NextI18Next from 'next-i18next'

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['ko'],
  defaultNS: 'common',
  localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
})

export const { appWithTranslation, withTranslation, useTranslation } = NextI18NextInstance
export default NextI18NextInstance

export const includeDefaultNamespaces = (namespaces: string[]) => ['common'].concat(namespaces)

export type I18Page<P = Record<string, never>> = NextComponentType<
  NextPageContext,
  { namespacesRequired: string[] },
  P & { namespacesRequired: string[] }
>
