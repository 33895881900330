import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

export const updateKDTApplyStep = createAction('apply/UPDATE_KDT_APPLY_STEP')<number>()

export const checkCanApply = createAsyncAction(
  'apply/CHECK_CAN_APPLY',
  'apply/CHECK_CAN_APPLY_SUCCESS',
  'apply/CHECK_CAN_APPLY_FAIL',
)<unknown, boolean, AxiosError>()

export const clearApply = createAction('apply/CLEAR_APPLY')<void>()
