import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import { IPurchaseOrderInfo } from '.'
import { ICreditCardResponse, IOrderInfo, IPayment } from './types'

export const updateCreditCard = createAsyncAction(
  'order/UPDATE_CREDIT_CARD',
  'order/UPDATE_CREDIT_CARD_SUCCESS',
  'order/UPDATE_CREDIT_CARD_FAIL',
)<unknown, ICreditCardResponse, AxiosError>()

export const fetchCreditCard = createAsyncAction(
  'order/FETCH_CREDIT_CARD',
  'order/FETCH_CREDIT_CARD_SUCCESS',
  'order/FETCH_CREDIT_CARD_FAIL',
)<unknown, ICreditCardResponse[], AxiosError>()

export const clearEnrollment = createAction('order/CLEAR_ENROLL_COURSE')<void>()

export const fetchCarts = createAsyncAction(
  'order/FETCH_CARTS',
  'order/FETCH_CARTS_SUCCESS',
  'order/FETCH_CARTS_FAIL',
)<unknown, IOrderInfo, AxiosError>()

export const applyCoupon = createAsyncAction(
  'order/APPLY_COUPON',
  'order/APPLY_COUPON_SUCCESS',
  'order/APPLY_COUPON_FAIL',
)<unknown, IPayment, AxiosError>()

export const requestPaymentsAction = createAsyncAction(
  'order/REQUEST_PAYMENTS',
  'order/REQUEST_PAYMENTS_SUCCESS',
  'order/REQUEST_PAYMENTS_FAIL',
)<unknown, IPayment, AxiosError>()

export const fetchPaymentsHistory = createAsyncAction(
  'order/FETCH_PAYMENTS_HISTORY',
  'order/FETCH_PAYMENTS_HISTORY_SUCCESS',
  'order/FETCH_PAYMENTS_HISTORY_FAIL',
)<unknown, IListDataConvertResp<IPayment>, AxiosError>()

export const clearOrder = createAction('order/CLEAR_ORDER')<void>()

export const fetchPurchaseOrderInfo = createAsyncAction(
  'order/FETCH_PURCHASE_ORDER_INFO',
  'order/FETCH_PURCHASE_ORDER_INFO_SUCCESS',
  'order/FETCH_PURCHASE_ORDER_INFO_FAIL',
)<unknown, IPurchaseOrderInfo, AxiosError>()

export const clearPurchaseOrder = createAction('order/CLEAR_PURCHASE_ORDER')<void>()
