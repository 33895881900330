import createAsyncThunk from '../../utils/createAsyncThunk'
import {
  applyCoupon,
  fetchCarts,
  fetchCreditCard,
  fetchPaymentsHistory,
  fetchPurchaseOrderInfo,
  requestPaymentsAction,
  updateCreditCard,
} from './actions'
import {
  requestCarts,
  requestFetchCreditCard,
  requestPayments,
  requestPaymentsHistory,
  requestPurchaseOrderInfo,
  requestUpdateCreditCard,
  updateCarts,
} from './api'

export const fetchCreditCardThunk = createAsyncThunk(fetchCreditCard, requestFetchCreditCard)

export const updateCreditCardThunk = createAsyncThunk(updateCreditCard, requestUpdateCreditCard)

export const fetchCartsThunk = createAsyncThunk(fetchCarts, requestCarts)

export const applyCouponThunk = createAsyncThunk(applyCoupon, updateCarts)

export const requestPaymentsThunk = createAsyncThunk(requestPaymentsAction, requestPayments)

export const requestPaymentsHistoryThunk = createAsyncThunk(
  fetchPaymentsHistory,
  requestPaymentsHistory,
)

export const fetchPurchaseOrderInfoThunk = createAsyncThunk(
  fetchPurchaseOrderInfo,
  requestPurchaseOrderInfo,
)
