import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import {
  fetchPGList,
  fetchPGMemberDetail,
  fetchPGMemberList,
  fetchPGProfile,
  fetchPGQuestInfoList,
  fetchPGSameProfileInfo,
  fetchTMIInfo,
  requestPGGroundRuleConfirmAction,
  requestPGProfileCreateAction,
  updatePGGroundRuleAction,
} from './actions'
import {
  IPeerGroup,
  IPeerGroupsAction,
  IPGMemberProfile,
  IPGProfile,
  IPGQuestInfo,
  IPGSameProfileInfo,
  IPGTMIInfo,
} from './types'

export const isLoading = createReducer<boolean, IPeerGroupsAction>(false as boolean)
  .handleAction(
    [
      fetchPGProfile.request,
      requestPGProfileCreateAction.request,
      fetchPGList.request,
      fetchPGMemberList.request,
      fetchPGMemberDetail.request,
      requestPGGroundRuleConfirmAction.request,
      fetchTMIInfo.request,
      fetchPGSameProfileInfo.request,
      fetchPGQuestInfoList.request,
      updatePGGroundRuleAction.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchPGProfile.success,
      fetchPGProfile.failure,
      requestPGProfileCreateAction.success,
      requestPGProfileCreateAction.failure,
      fetchPGList.success,
      fetchPGList.failure,
      fetchPGMemberList.success,
      fetchPGMemberList.failure,
      fetchPGMemberDetail.success,
      fetchPGMemberDetail.failure,
      requestPGGroundRuleConfirmAction.success,
      requestPGGroundRuleConfirmAction.failure,
      fetchTMIInfo.success,
      fetchTMIInfo.failure,
      fetchPGSameProfileInfo.success,
      fetchPGSameProfileInfo.failure,
      fetchPGQuestInfoList.success,
      fetchPGQuestInfoList.failure,
      updatePGGroundRuleAction.success,
      updatePGGroundRuleAction.failure,
    ],
    () => false,
  )

export const pgList = createReducer<IPeerGroup[], IPeerGroupsAction>([])
  .handleAction(fetchPGList.success, (state, action) => action.payload.data)
  .handleAction(updatePGGroundRuleAction.success, (state, action) => [action.payload])

export const pgProfile = createReducer<IPGProfile | null, IPeerGroupsAction>(null)
  .handleAction(fetchPGProfile.success, (state, action) => action.payload)
  .handleAction(requestPGProfileCreateAction.success, (state, action) => action.payload)
  .handleAction(requestPGGroundRuleConfirmAction.success, (state, action) => action.payload)

export const pgLeaderProfile = createReducer<IPGProfile | null, IPeerGroupsAction>(
  null,
).handleAction(fetchPGMemberDetail.success, (state, action) => action.payload)

export const pgMemberList = createReducer<IPGMemberProfile[], IPeerGroupsAction>([]).handleAction(
  fetchPGMemberList.success,
  (state, action) => action.payload,
)

export const pgTMIInfo = createReducer<IPGTMIInfo | null, IPeerGroupsAction>(null).handleAction(
  fetchTMIInfo.success,
  (state, action) => action.payload,
)

export const pgSameProfileInfo = createReducer<IPGSameProfileInfo | null, IPeerGroupsAction>(
  null,
).handleAction(fetchPGSameProfileInfo.success, (state, action) => action.payload)

export const pgQuestInfoList = createReducer<IPGQuestInfo[], IPeerGroupsAction>([]).handleAction(
  fetchPGQuestInfoList.success,
  (state, action) => action.payload.data,
)

const peergroupsReducer = combineReducers({
  isLoading,
  pgList,
  pgProfile,
  pgMemberList,
  pgLeaderProfile,
  pgTMIInfo,
  pgSameProfileInfo,
  pgQuestInfoList,
})

export default peergroupsReducer
export type IPeerGroupsState = ReturnType<typeof peergroupsReducer>
