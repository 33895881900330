import axios from 'axios'

import { convertCaseList, ConvertType } from '../../utils/convertString'
import { getApiEndpoint } from '../../utils/getApiEndpoint'
import { IPurchaseOrderInfo, IPurchaseOrderInfoResponse } from '.'
import {
  ICreditCard,
  IOrderInfo,
  IPayment,
  IPaymentParams,
  IPaymentResponse,
  IUpdateCartsParams,
} from './types'

export const requestFetchCreditCard = async () => {
  const resp = await axios.get('/api/my/payments/cards')
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestUpdateCreditCard = async (data: ICreditCard) => {
  const resp = await axios.post('/api/my/payments/cards', convertCaseList(data, ConvertType.SNAKE))
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestCarts = async (courseId: string) => {
  const resp = await axios.post(`${window.apiEndpoint}/my/payments/carts?course_id=${courseId}`)
  return convertCaseList(resp.data, ConvertType.CAMEL) as IOrderInfo
}

export const updateCarts = async (paymentId: string, data: IUpdateCartsParams) => {
  const resp = await axios.patch(
    `${window.apiEndpoint}/my/payments/carts/${paymentId}`,
    convertCaseList(data, ConvertType.SNAKE),
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IPayment
}

export const requestPayments = async (data: IPaymentParams) => {
  const resp = await axios.post<IPaymentResponse>(
    `${window.apiEndpoint}/my/payments/payments`,
    convertCaseList(data, ConvertType.SNAKE),
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IPayment
}

export const requestPaymentsHistory = async (status?: 'paid' | 'refunded') => {
  const resp = await axios.get(
    `${window.apiEndpoint}/my/payments/payments?per_page=100${status ? `&status=${status}` : ''}`,
  )
  const paymentsHistory: IListDataResp<IPayment> = resp.data
  return {
    data: convertCaseList(paymentsHistory.data, ConvertType.CAMEL),
    pagination: convertCaseList(paymentsHistory.pagination, ConvertType.CAMEL),
  }
}

export const requestPurchaseOrderInfo = async (poId: string) => {
  const apiEndpoint = getApiEndpoint()
  const resp = await axios.get<IPurchaseOrderInfoResponse>(
    `${apiEndpoint}/my/payment/v1/purchase-orders/${poId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IPurchaseOrderInfo
}
