import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import { IProfile, ITermsOfService } from './types'

export const fetchProfile = createAsyncAction(
  'auth/FETCH_PROFILE',
  'auth/FETCH_PROFILE_SUCCESS',
  'auth/FETCH_PROFILE_FAIL',
)<unknown, IProfile, AxiosError>()

export const fetchRole = createAsyncAction(
  'auth/FETCH_ROLE',
  'auth/FETCH_ROLE_SUCCESS',
  'auth/FETCH_ROLE_FAIL',
)<unknown, string[], AxiosError>()

export const updateProfile = createAsyncAction(
  'auth/CHANGE_PROFILE',
  'auth/CHANGE_PROFILE_SUCCESS',
  'auth/CHANGE_PROFILE_FAIL',
)<unknown, void, AxiosError>()

export const checkPassword = createAsyncAction(
  'auth/CHECK_PASSWORD',
  'auth/CHECK_PASSWORD_SUCCESS',
  'auth/CHECK_PASSWORD_FAIL',
)<unknown, void, AxiosError>()

export const changePassword = createAsyncAction(
  'auth/CHANGE_PASSWORD',
  'auth/CHANGE_PASSWORD_SUCCESS',
  'auth/CHANGE_PASSWORD_FAIL',
)<unknown, void, AxiosError>()

export const fetchTermsOfService = createAsyncAction(
  'auth/FETCH_TERMS_OF_SERVICE',
  'auth/FETCH_TERMS_OF_SERVICE_SUCCESS',
  'auth/FETCH_TERMS_OF_SERVICE_FAIL',
)<unknown, ITermsOfService, AxiosError>()

export const updateTermsOfService = createAsyncAction(
  'auth/UPDATE_TERMS_OF_SERVICE',
  'auth/UPDATE_TERMS_OF_SERVICE_SUCCESS',
  'auth/UPDATE_TERMS_OF_SERVICE_FAIL',
)<unknown, void, AxiosError>()

export const clearIsCheckedPassword = createAction('auth/CLEAR_IS_CHECKED_PASSWORD')<void>()
export const clearIsCheckedPasswordError = createAction(
  'auth/CLEAR_IS_CHECKED_PASSWORD_ERROR',
)<void>()

export const clearIsUpdatedTermsOfService = createAction(
  'auth/CLEAR_IS_UPDATED_TERMS_OF_SERVICE',
)<void>()
export const clearIsUpdatedPassword = createAction('auth/CLEAR_IS_UPDATED_PASSWORD')<void>()
export const clearProfile = createAction('auth/CLEAR_PROFILE')<void>()
