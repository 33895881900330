import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { clearIsCheckedPasswordError } from '.'
import {
  changePassword,
  checkPassword,
  clearIsCheckedPassword,
  clearIsUpdatedPassword,
  clearIsUpdatedTermsOfService,
  clearProfile,
  fetchProfile,
  fetchRole,
  fetchTermsOfService,
  updateProfile,
  updateTermsOfService,
} from './actions'
import { IAuthAction, IProfile, ITermsOfService } from './types'

const isLoading = createReducer<boolean, IAuthAction>(false as boolean)
  .handleAction(
    [
      fetchProfile.request,
      updateProfile.request,
      fetchRole.request,
      checkPassword.request,
      changePassword.request,
      fetchTermsOfService.request,
      updateTermsOfService.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchProfile.success,
      fetchProfile.failure,
      updateProfile.success,
      updateProfile.failure,
      fetchRole.success,
      fetchRole.failure,
      checkPassword.success,
      checkPassword.failure,
      changePassword.success,
      changePassword.failure,
      fetchTermsOfService.success,
      fetchTermsOfService.failure,
      updateTermsOfService.success,
      updateTermsOfService.failure,
    ],
    () => false,
  )

const profile = createReducer<IProfile | null, IAuthAction>(null)
  .handleAction(fetchProfile.success, (state, action) => action.payload)
  .handleAction(clearProfile, () => null)

const role = createReducer<string[] | null, IAuthAction>(null).handleAction(
  fetchRole.success,
  (state, action) => action.payload,
)

const isUpdatedProfile = createReducer<boolean | null, IAuthAction>(null)
  .handleAction(updateProfile.success, () => true)
  .handleAction(updateProfile.failure, () => false)
  .handleAction(clearProfile, () => null)

const isCheckedPassword = createReducer<boolean, IAuthAction>(false)
  .handleAction(checkPassword.success, () => true)
  .handleAction(clearIsCheckedPassword, () => false)

const isCheckedPasswordError = createReducer<boolean, IAuthAction>(false)
  .handleAction(checkPassword.failure, () => true)
  .handleAction([clearIsCheckedPasswordError, clearIsCheckedPassword], () => false)

export const isUpdatedTermsOfService = createReducer<boolean | null, IAuthAction>(null)
  .handleAction(updateTermsOfService.success, () => true)
  .handleAction(updateTermsOfService.failure, () => false)
  .handleAction([clearIsUpdatedTermsOfService, clearIsCheckedPassword], () => null)

export const isUpdatedPassword = createReducer<boolean | null, IAuthAction>(null)
  .handleAction(changePassword.success, () => true)
  .handleAction(changePassword.failure, () => false)
  .handleAction([clearIsUpdatedPassword, clearIsCheckedPassword], () => null)

const termsOfService = createReducer<ITermsOfService, IAuthAction>({
  marketingAgreement: {
    sms: false,
    email: false,
  },
})
  .handleAction(fetchTermsOfService.success, (state, action) => action.payload)
  .handleAction(clearProfile, () => ({
    marketingAgreement: {
      sms: false,
      email: false,
    },
  }))

const authReducer = combineReducers({
  isLoading,
  profile,
  role,
  isCheckedPassword,
  isCheckedPasswordError,
  termsOfService,
  isUpdatedTermsOfService,
  isUpdatedPassword,
  isUpdatedProfile,
})

export default authReducer
export type IAuthState = ReturnType<typeof authReducer>
