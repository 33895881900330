import axios from 'axios'
import { ThunkAction } from 'redux-thunk'

import createAsyncThunk from '../../utils/createAsyncThunk'
import { RootState } from '../index'
import {
  fetchPGList,
  fetchPGMemberDetail,
  fetchPGMemberList,
  fetchPGProfile,
  fetchPGQuestInfoList,
  fetchPGSameProfileInfo,
  fetchTMIInfo,
  requestPGGroundRuleConfirmAction,
  requestPGProfileCreateAction,
  requestPGQuestInfoListCreateAction,
  requestPGQuestInfoListDeleteAction,
  updatePGGroundRuleAction,
  updatePGQuestInfoListAction,
} from './actions'
import {
  requestPGGroundRuleConfirm,
  requestPGList,
  requestPGMemberDetail,
  requestPGMemberList,
  requestPGProfile,
  requestPGProfileCreate,
  requestPGQuestInfoList,
  requestPGQuestInfoListCreate,
  requestPGQuestInfoListDelete,
  requestPGSameProfileInfo,
  requestTMI,
  updatePGGroundRule,
  updatePGQuestInfoList,
} from './api'
import {
  IPeerGroupsAction,
  IPGQuestInfoListCreateParams,
  IPGQuestInfoListUpdateParams,
} from './types'

export const fetchPGListThunk = createAsyncThunk(fetchPGList, requestPGList)
export const fetchPGProfileThunk = createAsyncThunk(fetchPGProfile, requestPGProfile)
export const fetchPGMemberListThunk = createAsyncThunk(fetchPGMemberList, requestPGMemberList)
export const fetchPGMemberDetailThunk = createAsyncThunk(fetchPGMemberDetail, requestPGMemberDetail)
export const requestPGProfileCreateThunk = createAsyncThunk(
  requestPGProfileCreateAction,
  requestPGProfileCreate,
)
export const requestPGGroundRuleConfirmThunk = createAsyncThunk(
  requestPGGroundRuleConfirmAction,
  requestPGGroundRuleConfirm,
)

export const fetchTMIInfoThunk = createAsyncThunk(fetchTMIInfo, requestTMI)
export const fetchPGSameProfileInfoThunk = createAsyncThunk(
  fetchPGSameProfileInfo,
  requestPGSameProfileInfo,
)

export const fetchPGQuestInfoListThunk = createAsyncThunk(
  fetchPGQuestInfoList,
  requestPGQuestInfoList,
)

export const updatePGGroundRuleThunk = createAsyncThunk(
  updatePGGroundRuleAction,
  updatePGGroundRule,
)

export function requestPGQuestInfoListCreateThunk(
  groupId: string,
  data: IPGQuestInfoListCreateParams,
): ThunkAction<Promise<void>, RootState, null, IPeerGroupsAction> {
  return async (dispatch, getState) => {
    const { request, success, failure } = requestPGQuestInfoListCreateAction
    dispatch(request(undefined))
    try {
      const payload = await requestPGQuestInfoListCreate(groupId, data)
      dispatch(success(payload))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    } finally {
      await dispatch(fetchPGQuestInfoListThunk(groupId))
    }
  }
}

export function requestPGQuestInfoListDeleteThunk(
  groupId: string,
  questId: string,
): ThunkAction<Promise<void>, RootState, null, IPeerGroupsAction> {
  return async (dispatch, getState) => {
    const { request, success, failure } = requestPGQuestInfoListDeleteAction
    dispatch(request(undefined))
    try {
      const payload = await requestPGQuestInfoListDelete(groupId, questId)
      dispatch(success(payload))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    } finally {
      await dispatch(fetchPGQuestInfoListThunk(groupId))
    }
  }
}

export function updatePGQuestInfoListThunk(
  groupId: string,
  questId: string,
  data: IPGQuestInfoListUpdateParams,
): ThunkAction<Promise<void>, RootState, null, IPeerGroupsAction> {
  return async (dispatch, getState) => {
    const { request, success, failure } = updatePGQuestInfoListAction
    dispatch(request(undefined))
    try {
      const payload = await updatePGQuestInfoList(groupId, questId, data)
      dispatch(success(payload))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    } finally {
      await dispatch(fetchPGQuestInfoListThunk(groupId))
    }
  }
}
