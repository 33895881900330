import axios from 'axios'

import { convertCaseList, ConvertType } from '../../utils/convertString'
import {
  IPeerGroup,
  IPeerGroupResponse,
  IPGGroundRuleUpdateParams,
  IPGMemberProfileResponse,
  IPGProfile,
  IPGProfileCreate,
  IPGProfileResponse,
  IPGQuestInfo,
  IPGQuestInfoListCreateParams,
  IPGQuestInfoListUpdateParams,
  IPGQuestInfoResponse,
  IPGSameProfileInfo,
  IPGTMIInfo,
} from './types'

export const requestPGList = async (filters?: string) => {
  const resp = await axios.get<IListDataResp<IPeerGroupResponse>>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups`,
    {
      params: {
        filters,
      },
    },
  )

  const pgList: IListDataResp<IPeerGroupResponse> = resp.data

  return {
    data: convertCaseList(pgList.data, ConvertType.CAMEL) as IPeerGroup[],
    pagination: convertCaseList(pgList.pagination, ConvertType.CAMEL),
  }
}

export const updatePGGroundRule = async (
  groupId: string,
  payload: IPGGroundRuleUpdateParams,
  successHandler: () => void,
) => {
  const resp = await axios.patch<IPeerGroupResponse>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}`,
    payload,
  )

  if (resp.status === 200) {
    successHandler()
  }

  return convertCaseList(resp.data, ConvertType.CAMEL) as IPeerGroup
}

export const requestPGProfile = async (courseId: string) => {
  const resp = await axios.get<IPGProfileResponse>(
    `${window.apiEndpoint}/my/prgp/v1/group-profiles/${courseId}`,
  )

  return resp.data ? (convertCaseList(resp.data, ConvertType.CAMEL) as IPGProfile) : null
}

export const requestPGMemberList = async (groupId: string) => {
  const resp = await axios.get<IPGMemberProfileResponse[]>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/members`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IPGProfile[]
}

export const requestPGMemberDetail = async (groupId: string, userId: string) => {
  const resp = await axios.get<IPGProfileResponse>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/members/${userId}`,
  )

  return resp.data ? (convertCaseList(resp.data, ConvertType.CAMEL) as IPGProfile) : null
}

export const requestPGProfileCreate = async (data: IPGProfileCreate) => {
  const resp = await axios.post<IPGProfileResponse>(
    `${window.apiEndpoint}/my/prgp/v1/group-profiles`,
    convertCaseList(data, ConvertType.SNAKE),
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IPGProfile
}

export const requestPGGroundRuleConfirm = async (groupId: string) => {
  const resp = await axios.post(`${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/confirm`)

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestTMI = async (groupId: string) => {
  const resp = await axios.get<IPGTMIInfo>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/too-much-info`,
  )

  return resp.data
}

export const requestPGSameProfileInfo = async (groupId: string) => {
  const resp = await axios.get<IPGSameProfileInfo>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/selection`,
  )

  return resp.data
}

export const requestPGQuestInfoList = async (groupId: string) => {
  const resp = await axios.get<IListDataResp<IPGQuestInfoResponse>>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/progress-quests`,
    {
      params: {
        per_page: 1000,
      },
    },
  )

  const pgQuestInfoList: IListDataResp<IPGQuestInfoResponse> = resp.data

  return {
    data: convertCaseList(pgQuestInfoList.data, ConvertType.CAMEL) as IPGQuestInfo[],
    pagination: convertCaseList(pgQuestInfoList.pagination, ConvertType.CAMEL),
  }
}

export const requestPGQuestInfoListCreate = async (
  groupId: string,
  payload: IPGQuestInfoListCreateParams,
) => {
  const resp = await axios.post<IPGQuestInfo>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/progress-quests`,
    convertCaseList(payload, ConvertType.SNAKE),
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestPGQuestInfoListDelete = async (groupId: string, questId: string) => {
  const resp = await axios.delete<boolean>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/progress-quests/${questId}`,
  )

  return resp.data
}

export const updatePGQuestInfoList = async (
  groupId: string,
  questId: string,
  payload: IPGQuestInfoListUpdateParams,
) => {
  const resp = await axios.patch<IPGQuestInfo>(
    `${window.apiEndpoint}/my/prgp/v1/peer-groups/${groupId}/progress-quests/${questId}`,
    convertCaseList(payload, ConvertType.SNAKE),
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}
