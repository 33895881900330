import axios from 'axios'
import _ from 'lodash'
import { ThunkAction } from 'redux-thunk'

import createAsyncThunk from '../../utils/createAsyncThunk'
import { ICourse } from '../courses'
import { RootState } from '../index'
import { getCourseStatus, IPermissionAction } from '.'
import { fetchEnrollment, fetchEnrollmentList } from './actions'
import { requestEnrollment } from './api'
import { checkCourseStatus } from './utils'

export const fetchEnrollmentThunk = createAsyncThunk(fetchEnrollment, requestEnrollment)

export function getCourseStatusThunk(
  courseId: string,
): ThunkAction<Promise<void>, RootState, null, IPermissionAction> {
  return async (dispatch, getState) => {
    const { request, success, failure } = getCourseStatus
    dispatch(request(undefined))
    try {
      await dispatch(fetchEnrollmentThunk(courseId))

      const { enrollment } = getState().permission
      const { sections } = getState().courses
      const course: ICourse = _.omit(sections, 'chapters')

      const status = checkCourseStatus(course, enrollment)

      dispatch(success(status))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    }
  }
}
