import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { checkCanApply, updateKDTApplyStep } from './actions'
import { IApplyAction } from './types'

export const isLoading = createReducer<boolean, IApplyAction>(false as boolean)
  .handleAction([checkCanApply.request], () => true)
  .handleAction([checkCanApply.success, checkCanApply.failure], () => false)

export const kdtApplyStep = createReducer<number, IApplyAction>(0).handleAction(
  updateKDTApplyStep,
  (state, action) => action.payload,
)

export const canApply = createReducer<boolean | null, IApplyAction>(null).handleAction(
  checkCanApply.success,
  (state, action) => action.payload,
)

const applyReducer = combineReducers({
  isLoading,
  canApply,
  kdtApplyStep,
})

export default applyReducer
export type IApplyState = ReturnType<typeof applyReducer>
