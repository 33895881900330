import axios from 'axios'
import { push } from 'connected-next-router'
import _ from 'lodash'
import { ThunkAction } from 'redux-thunk'

import { RootState } from '../../stores'
import { fetchCourseSectionsThunk } from '../../stores/courses'
import { fetchEnrollmentThunk } from '../../stores/permission'
import { checkCanApply } from './actions'
import { IApplyAction } from './types'
import { checkCanApplyCourse } from './utils'

export function getApplyStatusThunk(
  courseId: string,
): ThunkAction<Promise<void>, RootState, null, IApplyAction> {
  return async (dispatch, getState) => {
    const { request, success, failure } = checkCanApply
    dispatch(request(undefined))
    try {
      await dispatch(fetchCourseSectionsThunk(courseId))
      await dispatch(fetchEnrollmentThunk(courseId))

      const { enrollment } = getState().permission
      const { sections } = getState().courses
      const course = _.omit(sections, 'chapters')

      const canApply = checkCanApplyCourse(course, enrollment)

      dispatch(success(canApply))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    }
  }
}
