/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */
export interface ICSProfile {
  name: string
  mobileNumber: string
  email: string
  referrer: string
}

export interface IWebInfo {
  browserName: string
  osName: string
  device: string
}

export interface ICSSettings {
  pluginKey: string
  createdAt: Date
  memberId?: string
  profile?: ICSProfile
  web?: IWebInfo
  unsubscribeEmail?: boolean
  unsubscribeTexting?: boolean
  zIndex?: number
}

class ChannelService {
  loadScript() {
    var w = window
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.',
      )
    }

    var ch = function () {
      ch.c(arguments)
    } as any
    ch.q = []
    ch.c = function (args: any) {
      ch.q.push(args)
    }
    w.ChannelIO = ch
    function l() {
      if (w.ChannelIOInitialized) {
        return
      }
      w.ChannelIOInitialized = true
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      s.charset = 'UTF-8'
      var x = document.getElementsByTagName('script')[0]
      x.parentNode!.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (window.attachEvent) {
      window.attachEvent('onload', l)
    } else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }
  }

  boot(settings: any) {
    window.ChannelIO('boot', settings)
  }

  setpage(page: string) {
    window.ChannelIO('setPage', page)
  }

  shutdown() {
    window.ChannelIO('shutdown')
  }

  show() {
    window.ChannelIO('showChannelButton')
  }

  hide() {
    window.ChannelIO('hideChannelButton')
  }
}

export default ChannelService
