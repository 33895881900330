import _ from 'lodash'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
interface IUtmQueryParams {
  children: ReactNode
}
interface IUtmContextState {
  utm_campaign: string
  utm_content: string
  utm_medium: string
  utm_source: string
  utm_term: string
}

const UtmAndGclidContext = createContext<IUtmContextState | undefined>({
  utm_campaign: '',
  utm_content: '',
  utm_medium: '',
  utm_source: '',
  utm_term: '',
})

export const UtmAndGclidQueryParamsProvider = ({ children }: IUtmQueryParams) => {
  const router = useRouter()
  const [utmAndGclid, setUtmAndGclid] = useState<IUtmContextState>()

  useEffect(() => {
    if (router.query) {
      const getUtm: any = _.pickBy(router.query, (value, key) => {
        return _.startsWith(key, 'utm') || _.startsWith(key, 'gclid')
      })
      if (!_.isEmpty(getUtm)) {
        sessionStorage.setItem('utmAndGclid', JSON.stringify(getUtm))
        setUtmAndGclid(getUtm)
      } else {
        sessionStorage.removeItem('utmAndGclid')
        setUtmAndGclid(undefined)
      }
    }
  }, [])

  return <UtmAndGclidContext.Provider value={utmAndGclid}>{children}</UtmAndGclidContext.Provider>
}

export const useUtmAndGclidQueryParams = () => useContext(UtmAndGclidContext)
