import axios from 'axios'
import QueryString from 'qs'

import { convertCaseList, ConvertType } from '../../utils/convertString'
import { getApiEndpoint } from '../../utils/getApiEndpoint'
import {
  ICourse,
  ICourseResponse,
  ICourseSections,
  ICourseSectionsResponse,
  IFAQPost,
  IKDTKlass,
  IKDTKlassProgressStatResponse,
  IKDTKlassResponse,
  IProgressResource,
  IProgressResponse,
} from './types'

export const requestKDTKlassAttr = async (courseId: string) => {
  const apiEndpoint = getApiEndpoint()
  const resp = await axios.get<IKDTKlassResponse>(
    `${apiEndpoint}/course/v1/courses/${courseId}/kdt-attr`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IKDTKlass
}
export const requestKDTBasicKlassList = async (klassId: string) => {
  const resp = await axios.get<IKDTKlassResponse[]>(
    `${window.apiEndpoint}/course/v1/courses/${klassId}/kdt-basics`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IKDTKlass[]
}

export const requestCourseList = async (courseType?: string) => {
  const requestQueryString = QueryString.stringify({
    ...(courseType ? { filters: `related_menu:${courseType}` } : {}),
  })
  const resp = await axios.get<IListDataResp<ICourse>>(
    `${window.apiEndpoint}/courses?${requestQueryString}`,
  )
  const courseList: IListDataResp<ICourse> = resp.data
  return {
    data: convertCaseList(courseList.data, ConvertType.CAMEL),
    pagination: convertCaseList(courseList.pagination, ConvertType.CAMEL),
  }
}

export const requestCourseDetail = async (courseId: string) => {
  const resp = await axios.get<ICourseResponse>(
    `${window.apiEndpoint}/course/v1/courses/${courseId}`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourse
}

export const requestCourseSections = async (courseId: string) => {
  const apiEndpoint = getApiEndpoint()
  const resp = await axios.get<ICourseSectionsResponse>(
    `${apiEndpoint}/course/v1/courses/${courseId}/sections`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourseSections
}

// export const requestCourseCurriculum = async (courseId: string) => {
//   return axios.get<IChapter[]>(`${window.apiEndpoint}/courses/${courseId}/chapters`).then(resp => {
//     return Promise.all(
//       resp.data.map(async (chapter: IChapter) => {
//         const resources = await requestCurriculumResourceList(courseId, chapter.id)
//         const totalSeconds = resources.reduce((a, b) => {
//           return a + b.info.total_seconds
//         }, 0)
//         return { ...chapter, resources, totalSeconds }
//       }),
//     )
//   })
// }

// export const requestCurriculumResourceList = async (courseId: string, chapterId: string) => {
//   const resp = await axios.get<ICurriculumResource[]>(
//     `${window.apiEndpoint}/courses/${courseId}/chapters/${chapterId}/resources`,
//   )
//   return resp.data
// }

export const requestProgressList = async (courseId: string) => {
  const resp = await axios.get<IProgressResponse[]>(
    `${window.apiEndpoint}/my/progress/v1/${courseId}`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestKDTProgressStatList = async (klassId: string) => {
  const resp = await axios.get<IKDTKlassProgressStatResponse[]>(
    `${window.apiEndpoint}/my/progress/v1/kdt/stats/${klassId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestProgressNow = async (courseId: string) => {
  const resp = await axios.get<IProgressResource>(
    `${window.apiEndpoint}/my/progress/v1/${courseId}/now`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestRecommendCourseList = async () => {
  const resp = await axios.get<IListDataResp<ICourse>>(`${window.apiEndpoint}/cms/recommend`)
  const courseList: IListDataResp<ICourse> = resp.data
  return {
    data: convertCaseList(courseList.data, ConvertType.CAMEL),
    pagination: convertCaseList(courseList.pagination, ConvertType.CAMEL),
  }
}

export const requestCourseFAQList = async (courseId: string) => {
  const resp = await axios.get<{ data: IFAQPost[] }>(
    `${window.apiEndpoint}/course/v1/courses/${courseId}/faqs`,
  )
  return resp.data.data
}

export const requestKDCProgressList = async (klassId: string, attrId: string) => {
  const resp = await axios.get(
    `${window.apiEndpoint}/my/progress/v1/kdc/progresses/${klassId}/attrs/${attrId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestKDCProgressNow = async (klassId: string, attrId: string) => {
  const resp = await axios.get(
    `${window.apiEndpoint}/my/progress/v1/kdc/progresses/${klassId}/attrs/${attrId}/now`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}
