import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import { IQnAListItem, IQuestionCreateParams } from './types'

export const fetchQnAList = createAsyncAction(
  'qna/FETCH_QNA_LIST',
  'qna/FETCH_QNA_LIST_SUCCESS',
  'qna/FETCH_QNA_LIST_FAIL',
)<unknown, IListDataConvertResp<IQnAListItem>, AxiosError>()

export const clearQnAList = createAction('qna/CLEAR_QNA_LIST')<void>()

export const requestQuestionCreateAction = createAsyncAction(
  'qna/REQUEST_QUESTION_CREATE',
  'qna/REQUEST_QUESTION_CREATE_SUCCESS',
  'qna/REQUEST_QUESTION_CREATE_FAIL',
)<unknown, IQuestionCreateParams, AxiosError>()

export const updateQuestionAction = createAsyncAction(
  'qna/UPDATE_QUESTION',
  'qna/UPDATE_QUESTION_SUCCESS',
  'qna/UPDATE_QUESTION_FAIL',
)<unknown, IQuestionCreateParams, AxiosError>()

export const clearQnaPagination = createAction('qna/CLEAR_QNA_PAGINATION')<void>()
