export const detectBrowser = (navigator: Navigator) => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  } else if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge'
  } else {
    return 'Unknown'
  }
}

export const detectOSName = (navigator: Navigator) => {
  let operatingSystem = 'Not known'
  if (navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS'
  }
  if (navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS'
  }
  if (navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS'
  }
  if (navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS'
  }

  return operatingSystem
}

export const detectDeviceType = (navigator: Navigator) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      navigator.userAgent,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}
