import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid_v4 } from 'uuid'

import { RootState } from '../../stores'
import { IProfile } from '../../stores/auth'

export interface IAuthContextState {
  isSigned: boolean
  signInUrl: string
  profile: IProfile | null
  role: string
}

const AuthContext = createContext<IAuthContextState>({
  isSigned: false,
  signInUrl: '',
  profile: null,
  role: '',
})

export interface IAuthProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const [myRole, setMyRole] = useState<string>('')
  const { profile, role } = useSelector((state: RootState) => state.auth)

  const signInUrlWithNonce = useMemo(() => `${window.signInUrl}&nonce=${uuid_v4()}`, [])

  useEffect(() => {
    if (role) {
      const isRole = role.find(v => v === 'manager' || v === 'tutor')
      setMyRole(isRole ?? 'trainee')
    }
  }, [role])

  return (
    <AuthContext.Provider
      value={{ isSigned: profile !== null, signInUrl: signInUrlWithNonce, profile, role: myRole }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export const useAuth = () => useContext(AuthContext)
