import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import {
  clearCourseState,
  clearKDTProgressStatList,
  fetchCourseDetail,
  fetchCourseFAQList,
  fetchCourseSections,
  fetchKDTBasicKlassList,
  fetchKDTKlassAttr,
  fetchKDTKlassProgressStatList,
  fetchProgressList,
  fetchProgressNow,
  fetchRecommendCourseList,
} from './actions'
import {
  ICourse,
  ICourseAction,
  ICourseSections,
  IFAQPost,
  IKDTKlass,
  IKDTKlassProgressStat,
  IProgress,
  IProgressResource,
} from './types'

export const isLoading = createReducer<boolean, ICourseAction>(false)
  .handleAction(
    [
      fetchKDTKlassAttr.request,
      fetchKDTBasicKlassList.request,
      fetchCourseDetail.request,
      fetchCourseFAQList.request,
      fetchCourseSections.request,
      fetchProgressList.request,
      fetchKDTKlassProgressStatList.request,
      fetchProgressNow.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchKDTBasicKlassList.success,
      fetchKDTBasicKlassList.failure,
      fetchKDTKlassAttr.success,
      fetchKDTKlassAttr.failure,
      fetchCourseDetail.success,
      fetchCourseDetail.failure,
      fetchCourseFAQList.success,
      fetchCourseFAQList.failure,
      fetchCourseSections.success,
      fetchCourseSections.failure,
      fetchProgressList.success,
      fetchProgressList.failure,
      fetchKDTKlassProgressStatList.success,
      fetchKDTKlassProgressStatList.failure,
      fetchProgressNow.success,
      fetchProgressNow.failure,
    ],
    () => false,
  )

export const kdtAttr = createReducer<IKDTKlass | null, ICourseAction>(null)
  .handleAction(fetchKDTKlassAttr.success, (state, action) => action.payload)
  .handleAction(clearCourseState, () => null)

export const kdtBasicKlassList = createReducer<IKDTKlass[] | null, ICourseAction>(
  null,
).handleAction(fetchKDTBasicKlassList.success, (state, action) => action.payload)

export const course = createReducer<ICourse | null, ICourseAction>(null)
  .handleAction(fetchCourseDetail.success, (state, action) => action.payload)
  .handleAction(clearCourseState, () => null)

export const sections = createReducer<ICourseSections | null, ICourseAction>(null)
  .handleAction(fetchCourseSections.success, (state, action) => action.payload)
  .handleAction(clearCourseState, () => null)

export const progressList = createReducer<IProgress[], ICourseAction>([])
  .handleAction(fetchProgressList.success, (state, action) => action.payload)
  .handleAction(clearCourseState, () => [])

export const kdtProgressStatList = createReducer<IKDTKlassProgressStat[], ICourseAction>([])
  .handleAction(fetchKDTKlassProgressStatList.success, (state, action) => action.payload)
  .handleAction(clearKDTProgressStatList, () => [])

export const progressNow = createReducer<IProgressResource, ICourseAction>({
  id: null,
  courseId: null,
  chapterId: null,
  title: null,
  type: null,
  isCompleted: false,
  maintainSeconds: null,
  lastTimestamp: null,
  maxTimestamp: null,
  openStatus: null,
  info: {
    hls: '',
    dash: '',
    poster: '',
    totalSeconds: 0,
  },
})
  .handleAction(fetchProgressNow.success, (state, action) => action.payload)
  .handleAction(clearCourseState, () => ({
    id: null,
    courseId: null,
    chapterId: null,
    title: null,
    type: null,
    isCompleted: false,
    maintainSeconds: null,
    lastTimestamp: null,
    maxTimestamp: null,
    openStatus: null,
    info: {
      hls: '',
      dash: '',
      poster: '',
      totalSeconds: 0,
    },
  }))

export const recommendCourseList = createReducer<ICourse[], ICourseAction>([])
  .handleAction(fetchRecommendCourseList.success, (state, action) => action.payload.data)
  .handleAction(clearCourseState, () => [])

export const recommendCourseListPagination = createReducer<IListPagination, ICourseAction>({
  page: 1,
  perPage: 10,
  totalPage: 1,
  count: 0,
  totalCount: 0,
}).handleAction(fetchRecommendCourseList.success, (state, action) => action.payload.pagination)

export const faqList = createReducer<IFAQPost[], ICourseAction>([]).handleAction(
  fetchCourseFAQList.success,
  (state, action) => action.payload,
)

const courseReducer = combineReducers({
  isLoading,
  kdtAttr,
  kdtBasicKlassList,
  course,
  sections,
  progressList,
  kdtProgressStatList,
  progressNow,
  recommendCourseList,
  recommendCourseListPagination,
  faqList,
})

export default courseReducer
export type ICourseState = ReturnType<typeof courseReducer>
