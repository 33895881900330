const BebridgeAitChatbot = (userId: string, pathname: string) => {
  if (typeof window !== 'undefined' && window.BebridgeAIChatbot) {
    const { BebridgeAIChat } = window.BebridgeAIChatbot

    if (BebridgeAIChat) {
      BebridgeAIChat.init({
        pluginKey: 'ddc53011-0845-402c-914c-a8484657fb6f',
      })

      const chatbotInstance = new BebridgeAIChat()

      chatbotInstance.render({
        userKey: userId,
        botKey: '0031fc10-5c13-4268-b7aa-333bc9fe9001',
        hideDefaultChatButton: true,
      })

      chatbotInstance.closeChat()

      if (
        chatbotInstance &&
        [
          '/my/courses/detail/[schoolId]/board',
          '/my/courses/detail/[schoolId]/attendance',
          '/my/courses/detail/[schoolId]/curriculum',
          '/my/courses/detail/[schoolId]/notice',
          '/my/courses/detail/[schoolId]/notice/[noticeId]',
          '/my/courses/detail/[schoolId]/note',
          '/my/courses/detail/[schoolId]/qna',
          '/my/courses/detail/[schoolId]/qna/[qnaId]',
          '/my/courses/detail/[schoolId]/quiz',
          '/my/courses/detail/[schoolId]/quiz/[quizId]',
          '/my/courses/detail/[schoolId]/project',
          '/my/courses/detail/[schoolId]/consulting',
          '/my/courses/detail/[schoolId]/consulting/[consultId]',
          '/my/courses/detail/[schoolId]/survey',
          '/my/courses/detail/[schoolId]/job',
        ].includes(pathname)
      ) {
        chatbotInstance.showChatButton()
      } else {
        chatbotInstance.hideChatButton()
      }

      return chatbotInstance
    } else {
      console.error('BebridgeAIChat is not available.')
      return null
    }
  } else {
    console.error('BebridgeAIChatbot is not available on the window object.')
    return null
  }
}

export default BebridgeAitChatbot
