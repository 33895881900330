import { AnyAction, combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { clearQnAList, clearQnaPagination } from '.'
import { fetchQnAList } from './actions'
import { IQnaActions, IQnAListItem } from './types'

export const isLoading = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction([fetchQnAList.request], () => true)
  .handleAction([fetchQnAList.success, fetchQnAList.failure], () => false)

export const qnaList = createReducer<IQnAListItem[], IQnaActions>([])
  .handleAction(fetchQnAList.success, (state, action) => action.payload.data)
  .handleAction(clearQnAList, () => [])

export const myQnAList = createReducer<IQnAListItem[], IQnaActions>([])
  .handleAction(fetchQnAList.success, (state, action) => state.concat(action.payload.data))
  .handleAction(clearQnAList, () => [])

const qnaListPagination = createReducer<IListPagination, IQnaActions>({
  page: 1,
  perPage: 6,
  totalPage: 1,
  count: 0,
  totalCount: 0,
})
  .handleAction(fetchQnAList.success, (state, action) => action.payload.pagination)
  .handleAction(clearQnaPagination, () => ({
    page: 1,
    perPage: 6,
    totalPage: 1,
    count: 0,
    totalCount: 0,
  }))

const qnaReducer = combineReducers({
  isLoading,
  myQnAList,
  qnaList,
  qnaListPagination,
})

export default qnaReducer
export type IQnAState = ReturnType<typeof qnaReducer>
