import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import {
  IPeerGroup,
  IPGMemberProfile,
  IPGProfile,
  IPGQuestInfo,
  IPGSameProfileInfo,
  IPGTMIInfo,
} from './types'

export const fetchPGList = createAsyncAction(
  'peergroups/FETCH_PG_LIST',
  'peergroups/FETCH_PG_LIST_SUCCESS',
  'peergroups/FETCH_PG_LIST_FAIL',
)<unknown, IListDataConvertResp<IPeerGroup>, AxiosError>()

export const fetchPGProfile = createAsyncAction(
  'peergroups/FETCH_PG_PROFILE',
  'peergroups/FETCH_PG_PROFILE_SUCCESS',
  'peergroups/FETCH_PG_PROFILE_FAIL',
)<unknown, IPGProfile | null, AxiosError>()

export const fetchPGMemberList = createAsyncAction(
  'peergroups/REQUEST_PG_MEMBER_LIST',
  'peergroups/REQUEST_PG_MEMBER_LIST_SUCCESS',
  'peergroups/REQUEST_PG_MEMBER_LIST_FAIL',
)<unknown, IPGMemberProfile[], AxiosError>()

export const fetchPGMemberDetail = createAsyncAction(
  'peergroups/FETCH_PG_MEMBER_DETAIL',
  'peergroups/FETCH_PG_MEMBER_DETAIL_SUCCESS',
  'peergroups/FETCH_PG_MEMBER_DETAIL_FAIL',
)<unknown, IPGProfile | null, AxiosError>()

export const requestPGProfileCreateAction = createAsyncAction(
  'peergroups/REQUEST_PG_PROFILE_CREATE',
  'peergroups/REQUEST_PG_PROFILE_CREATE_SUCCESS',
  'peergroups/REQUEST_PG_PROFILE_CREATE_FAIL',
)<unknown, IPGProfile, AxiosError>()

export const requestPGGroundRuleConfirmAction = createAsyncAction(
  'peergroups/REQUEST_PG_GROUNDRULE_CONFIRM_CREATE',
  'peergroups/REQUEST_PG_GROUNDRULE_CONFIRM_SUCCESS',
  'peergroups/REQUEST_PG_GROUNDRULE_CONFIRM_FAIL',
)<unknown, IPGProfile, AxiosError>()

export const fetchTMIInfo = createAsyncAction(
  'peergroups/FETCH_TMI_INFO',
  'peergroups/FETCH_TMI_INFO_SUCCESS',
  'peergroups/FETCH_TMI_INFO_FAIL',
)<unknown, IPGTMIInfo, AxiosError>()

export const fetchPGSameProfileInfo = createAsyncAction(
  'peergroups/FETCH_PG_SAME_PROFILE_INFO',
  'peergroups/FETCH_PG_SAME_PROFILE_INFO_SUCCESS',
  'peergroups/FETCH_PG_SAME_PROFILE_INFO_FAIL',
)<unknown, IPGSameProfileInfo, AxiosError>()

export const fetchPGQuestInfoList = createAsyncAction(
  'peergroups/FETCH_PG_QUEST_INFO_LIST',
  'peergroups/FETCH_PG_QUEST_INFO_LIST_SUCCESS',
  'peergroups/FETCH_PG_QUEST_INFO_LIST_FAIL',
)<unknown, IListDataConvertResp<IPGQuestInfo>, AxiosError>()

export const updatePGGroundRuleAction = createAsyncAction(
  'peergroups/UPDATE_PG_GROUNDRULE',
  'peergroups/UPDATE_PG_GROUNDRULE_SUCCESS',
  'peergroups/UPDATE_PG_GROUNDRULE_FAIL',
)<unknown, IPeerGroup, AxiosError>()

export const requestPGQuestInfoListCreateAction = createAsyncAction(
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_CREATE',
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_CREATE_SUCCESS',
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_CREATE_FAIL',
)<unknown, IPGQuestInfo, AxiosError>()

export const requestPGQuestInfoListDeleteAction = createAsyncAction(
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_DELETE',
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_DELETE_SUCCESS',
  'peergroups/REQUEST_PG_QUEST_INFO_LIST_DELETE_FAIL',
)<unknown, boolean, AxiosError>()

export const updatePGQuestInfoListAction = createAsyncAction(
  'peergroups/UPDATE_PG_QUEST_INFO_LIST',
  'peergroups/UPDATE_PG_QUEST_INFO_LIST_SUCCESS',
  'peergroups/UPDATE_PG_QUEST_INFO_LIST_FAIL',
)<unknown, IPGQuestInfo, AxiosError>()

export const clearApply = createAction('peergroups/CLEAR_PG_PROFILE')<void>()
