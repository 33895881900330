import { AnyAction, combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { ICourseEnrollment } from '.'
import {
  clearCourseStatus,
  clearEnrollmentList,
  clearEnrollmentListPagination,
  clearPermission,
  fetchEnrollment,
  fetchEnrollmentList,
  getCourseStatus,
} from './actions'
import { CourseStatus, IEnrollmentHistory, IPermissionAction } from './types'

export const isLoading = createReducer<boolean, AnyAction>(false as boolean)
  .handleAction(
    [fetchEnrollmentList.request, fetchEnrollment.request, getCourseStatus.request],
    () => true,
  )
  .handleAction(
    [
      fetchEnrollmentList.success,
      fetchEnrollmentList.failure,
      fetchEnrollment.success,
      fetchEnrollment.failure,
      getCourseStatus.success,
      getCourseStatus.failure,
    ],
    () => false,
  )

export const enrollmentList = createReducer<IEnrollmentHistory[], IPermissionAction>([])
  .handleAction(fetchEnrollmentList.success, (state, action) => {
    if (state.length === 0) {
      return action.payload.data
    }
    return state.concat(action.payload.data)
  })
  .handleAction(clearEnrollmentList, () => [])

const enrollmentListPagination = createReducer<IListPagination, IPermissionAction>({
  page: 1,
  perPage: 6,
  totalPage: 1,
  count: 0,
  totalCount: 0,
})
  .handleAction(fetchEnrollmentList.success, (state, action) => action.payload.pagination)
  .handleAction(clearEnrollmentListPagination, () => ({
    page: 1,
    perPage: 6,
    totalPage: 1,
    count: 0,
    totalCount: 0,
  }))

export const enrollment = createReducer<ICourseEnrollment | null, IPermissionAction>(null)
  .handleAction(fetchEnrollment.success, (state, action) => action.payload)
  .handleAction(clearPermission, () => null)

export const courseStatus = createReducer<CourseStatus, IPermissionAction>('loading')
  .handleAction(getCourseStatus.success, (state, action) => action.payload)
  .handleAction(clearCourseStatus, () => 'loading')

const permissionReducer = combineReducers({
  isLoading,
  enrollment,
  enrollmentList,
  enrollmentListPagination,
  courseStatus,
})

export default permissionReducer
export type IPermissionState = ReturnType<typeof permissionReducer>
