import axios from 'axios'

import { convertCaseList, ConvertType } from '../../utils/convertString'
import { IQnAListItemResponse, IQuestionCreateParams, IUpdateQuestionParams } from './types'

export const requestQnAList = async (
  onlyMyQuestion: boolean = false,
  pagination: IPaginationParams,
  filters?: string,
) => {
  const resp = await axios.get<IListDataResp<IQnAListItemResponse>>(
    `${window.apiEndpoint}${onlyMyQuestion ? '/my' : ''}/sqna/v1/questions`,
    {
      params: {
        page: pagination.page ?? 1,
        per_page: pagination.perPage ?? 100,
        sort: 'created_at:desc',
        ...(typeof filters !== 'undefined' ? { filters } : undefined),
      },
    },
  )

  const qnaList: IListDataResp<IQnAListItemResponse> = resp.data
  return {
    data: convertCaseList(qnaList.data, ConvertType.CAMEL),
    pagination: convertCaseList(qnaList.pagination, ConvertType.CAMEL),
  }
}
export const requestQuestionCreate = async (data: IQuestionCreateParams) => {
  const resp = await axios.post(
    `${window.apiEndpoint}/my/sqna/v1/questions`,
    convertCaseList(data, ConvertType.SNAKE),
  )
  return resp.data
}

export const updateQuestion = async (questionId: string, data: IUpdateQuestionParams) => {
  const resp = await axios.patch(`${window.apiEndpoint}/my/sqna/v1/questions/${questionId}`, data)
  return resp.data
}
