import { AxiosError } from 'axios'
import { createAction, createAsyncAction } from 'typesafe-actions'

import { ICourseEnrollment, IEnrollmentHistory } from './types'

export const fetchEnrollmentList = createAsyncAction(
  'permission/FETCH_ENROLLMENT_LIST',
  'permission/FETCH_ENROLLMENT_LIST_SUCCESS',
  'permission/FETCH_ENROLLMENT_LIST_FAIL',
)<unknown, IListDataConvertResp<IEnrollmentHistory>, AxiosError>()

export const fetchEnrollment = createAsyncAction(
  'permission/FETCH_ENROLLMENT',
  'permission/FETCH_ENROLLMENT_SUCCESS',
  'permission/FETCH_ENROLLMENT_FAIL',
)<unknown, ICourseEnrollment, AxiosError>()

export const clearPermission = createAction('permission/CLEAR_PERMISSION')<void>()

export const getCourseStatus = createAsyncAction(
  'permission/GET_COURSE_STATUS',
  'permission/GET_COURSE_STATUS_SUCCESS',
  'permission/GET_COURSE_STATUS_FAIL',
)<unknown, unknown, AxiosError>()

export const clearCourseStatus = createAction('permission/CLEAR_COURSE_STATUS')<void>()
export const clearEnrollmentList = createAction('permission/CLEAR_ENROLLMENT_LIST')<void>()
export const clearEnrollmentListPagination = createAction(
  'permission/CLEAR_ENROLLMENT_LIST_PAGINATION',
)<void>()
